import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ClubPage.css"; // Updated CSS file for refined styling.

function ClubPage() {
    const { id } = useParams();
    const [clubData, setClubData] = useState(null);

    // Mock data for the club page
    const mockClubData = {
        id: 1,
        name: "Slice Tennis Club",
        address: "123 Tennis Street, Kalamaria, Thessaloniki",
        courts: [
            { id: 1, name: "Padel 1", availability: [8, 9, 10, 11] },
            { id: 2, name: "Padel 2", availability: [12, 13, 14, 15] },
            { id: 3, name: "Padel 3", availability: [16, 17, 18, 19] },
            { id: 4, name: "Padel 4", availability: [20, 21, 22, 23] },
        ],
        info: "Slice Tennis Club is a state-of-the-art sports venue with modern courts and facilities.",
        mapUrl: "https://via.placeholder.com/300x200.png?text=Map+Placeholder",
        openingHours: "Mon-Sun: 08:00 - 23:00",
        image: "https://via.placeholder.com/600x400.png?text=Sport+Club+Image",
    };

    useEffect(() => {
        setClubData(mockClubData);
    }, [id]);

    if (!clubData) {
        return <p>Loading...</p>;
    }

    return (
        <div className="club-page">

            <main className="content">
                <div className="club-header">
                    <a href="/results" className="back-link"> &lt; Explore nearby venues</a>
                    <h1 className="club-name">{clubData.name}</h1>
                    <p className="club-address">{clubData.address}</p>
                </div>

                <section className="schedule-section">
                    <div className="schedule-header">
                        <select>
                            <option value="Padel">Padel</option>
                        </select>
                        <input type="date" defaultValue={new Date().toISOString().split("T")[0]} />
                        <button>Filters</button>
                    </div>
                    <div className="schedule-grid">
                        {clubData.courts.map((court) => (
                            <div key={court.id} className="court-row">
                                <span className="court-name">{court.name}</span>
                                <div className="availability">
                                    {Array.from({ length: 16 }, (_, index) => (
                                        <div
                                            key={index}
                                            className={
                                                court.availability.includes(index + 8)
                                                    ? "available"
                                                    : "not-available"
                                            }
                                        ></div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="schedule-legend">
                        <span className="available-box"></span> Available
                        <span className="not-available-box"></span> Not Available
                        <span className="your-booking-box"></span> Your Booking
                    </div>
                </section>

                <section className="details-section">
                    <div className="info">
                        <h2>Sport Club Info</h2>
                        <p>{clubData.info}</p>
                        <img src={clubData.image} alt="Club" className="club-image" />
                    </div>
                    <div className="map">
                        <h2>Map</h2>
                        <img src={clubData.mapUrl} alt="Map" />
                    </div>
                    <div className="opening-hours">
                        <h2>Club Opening Hours</h2>
                        <p>{clubData.openingHours}</p>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default ClubPage;
