import * as React from 'react';
import { useNavigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOn from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { getCompanies } from "../../services/Company.service";
import './Searchbar.css';

function Searchbar() {
    const { t } = useTranslation();
    const courtTypes = ["Padel", "Tennis", "Football", "Basketball"];
    const [courtType, setCourtType] = React.useState("padel");
    const [value, setValue] = React.useState(""); // Input value
    const [options, setOptions] = React.useState([]); // Autocomplete options
    const navigate = useNavigate();

    // Fetch and filter court data by sport type
    React.useEffect(() => {
        const fetchCourts = async () => {
            try {
                const results = await getCompanies();
                const filteredCourts = results.data.filter((company) =>
                    company.sport.name.toLowerCase() === courtType
                );
                setOptions(filteredCourts); // Update options dynamically
            } catch (error) {
                console.error("Error fetching courts:", error);
            }
        };

        fetchCourts();
    }, [courtType]); // Re-fetch when courtType changes

    const handleCourtTypeChange = (event) => {
        setCourtType(event.target.value.toLowerCase()); // Update selected court type
        setValue(""); // Clear the input field
    };

    const handleSearch = () => {
        navigate(`/results`, { state: { courtType, searchText: value } }); // Pass data to results page
    };

    return (
        <Box className="home-container">
            <h1>{t('bookGame')}</h1>
            <Container
                className="search-container"
                maxWidth="xl"
                sx={{ paddingTop: '2.5rem', width: '100vw' }}
            >
                <SportsTennisIcon color="primary" />
                <label>{t('selectCourtType')}</label>
                <Box sx={{ flexGrow: 0 }}>
                    <Select
                        value={courtType}
                        onChange={handleCourtTypeChange}
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                    >
                        {courtTypes.map((type, index) => (
                            <MenuItem key={index} value={type.toLowerCase()}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Autocomplete
                    value={value}
                    onInputChange={(event, newValue) => {
                        setValue(newValue); // Update input value
                    }}
                    options={options.map((option) => option.name)} // Extract names for autocomplete
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOn />
                                    </InputAdornment>
                                ),
                            }}
                            label="Type location or club name"
                        />
                    )}
                    sx={{ width: 300 }}
                />

                <Button
                    startIcon={<SearchIcon />}
                    size="large"
                    onClick={handleSearch}
                    sx={{
                        bgcolor: 'primary.main',
                        color: 'primary.contrastText',
                        ':hover': {
                            bgcolor: 'primary.dark',
                            color: 'primary.contrastText',
                        },
                    }}
                >
                    {t('Search')}
                </Button>
            </Container>
        </Box>
    );
}

export default Searchbar;
