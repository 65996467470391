import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOn from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { getCompanies } from '../../services/Company.service';

function AutocompleteLocation({ courtType }) {
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]); // State to store court data

    // Fetch court data when the component mounts or courtType changes
    React.useEffect(() => {
        const fetchCourts = async () => {
            try {
                const results = await getCompanies({ courtType });
                const courtNames = results.data.map(court => court.name); // Extract court names
                setOptions(courtNames); // Set court names as options
            } catch (error) {
                console.error("Failed to fetch court data:", error);
            }
        };

        fetchCourts();
    }, [courtType]); // Refetch data if courtType changes

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue); // Set the selected value
            }}
            options={options} // Populate options with court names
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOn />
                            </InputAdornment>
                        )
                    }}
                    label="Select court"
                />
            )}
        />
    );
}

export default AutocompleteLocation;