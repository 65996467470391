import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompanies } from "../../services/Company.service";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './Results.css';
import Link from '@mui/material/Link';

function Results() {
    const location = useLocation();
    const navigate = useNavigate();
    const { courtType, searchText } = location.state; // Extract courtType and searchText
    const [courts, setCourts] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleNavigate = (id) => {
        navigate(`/ClubPage/${id}`); // Navigate to the new page with the club ID
    };
    useEffect(() => {
        const loadCourts = async () => {
            setLoading(true);
            const results = await getCompanies();
            const allCompanies = results?.data || [];
            const filteredCompanies = allCompanies.filter(company =>
                company.sport.name.toLowerCase() === courtType.toLowerCase() &&
                (
                    company.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    company.address.toLowerCase().includes(searchText.toLowerCase()) ||
                    company.area.toLowerCase().includes(searchText.toLowerCase()) ||
                    company.city.toLowerCase().includes(searchText.toLowerCase())
                )
            );
            setCourts(filteredCompanies);
            setLoading(false);
        };

        loadCourts();
    }, [courtType, searchText]);

    if (loading) {
        return <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2rem', color: 'text.secondary' }}>Loading...</Typography>;
    }

    return (
        <div className="court-list">
            {courts.length > 0 ? (
                courts.map((company) => (
                    <Card  variant="outlined" className="court" key={company.id} onClick={() => handleNavigate(company.id)}  style={{ cursor: "pointer" }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', color: "#44ad2f" }}>
                                {company.name}
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                {company.address}, {company.area}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {company.city}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {company.contact_number}
                            </Typography>
                            <Link href={company.url} target="_blank" sx={{ fontSize: '0.6em', display: 'flex', justifyContent: 'center' }}>
                                {company.url}
                            </Link>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2rem', color: 'text.secondary' }}>
                    No results found for {searchText}.
                </Typography>
            )}
        </div>
    );
}

export default Results;
